@import "variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "~base/components/categoryTiles";
@import "./utilities/typeUtils";
@import "./utilities/functions";

.button-set {
    position: relative;
    z-index: 1;
    color: $white;

    .button {
        font-weight: $font-weight-bolder;
    }

    .promotional-message {
        padding-left: rem(15px);
    }
}

.banner-heading {
    letter-spacing: rem(-0.75px);
}

.banner-heading,
.banner-subheading {
    font-family: $font-family-title;
    font-weight: $font-weight-semibold;
}

.container {
    .card {
        picture {
            min-height: rem(1px);
        }
    }
}

.homepage-slot {
    padding-top: rem(15px);
}

.text-section-wrapper {
    margin: rem(30px);
    width: calc(100% - (#{rem(30px)}) * 2); // 30px - side margins
    height: calc(100% - (#{rem(30px)}) * 2); // 30px - side margins
}

.transparent-bg {
    background-color: transparent;
}

.home-email-signup {
    background-color: $grey6;

    > .container {
        padding-top: 0.625em;
        padding-bottom: 0.625em;
    }

    .email-description {
        padding-top: 0.375em;
        color: $white;
    }
}
